import { defineStore } from "pinia";

export const useGenerateOrgMessageStore = defineStore("generate-org-message", {
	state: (): {
		aiGeneratesMessage: boolean;
		aiGeneratedSuccess: boolean;
	} => ({
		aiGeneratesMessage: false,
		aiGeneratedSuccess: false,
	}),
	actions: {
		setAiGeneratesMessage(status: boolean): void {
			this.aiGeneratesMessage = status;
		},
		setAiGeneratedSuccess(status: boolean): void {
			this.aiGeneratedSuccess = status;
		},
	},
	getters: {
		isAiGenerateMessage(): boolean {
			return this.aiGeneratesMessage;
		},
		isAiGeneratedSuccess(): boolean {
			return this.aiGeneratedSuccess;
		},
	},
});
