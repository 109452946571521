import { defineStore } from "pinia";
import { useOFetch } from "~/composables/useOFetch";
import { api } from "~/api/api";
import { isOrganizersChatEnabled } from "~/utils/organizers-chat-is-enable";

type TRoomFetching = "idle" | "pending" | "success" | "silent";

export const useRoomStore = defineStore("room", {
	state: (): {
		room: IRoom | null;
		roomFetching: TRoomFetching;
	} => ({
		room: null,
		roomFetching: "idle",
	}),
	actions: {
		async fetchEventRoom(silent: boolean = false): Promise<void> {
			if (!isOrganizersChatEnabled()) return;

			this.setRoomFetching(silent ? "silent" : "pending");
			const { activeEventStore } = useStores();

			const roomData: IApiResponsePayload<IRoom> = await useOFetch<IApiResponsePayload<IRoom>>(api.eventRoom(activeEventStore.getActiveEventId), {
				method: "GET",
			});

			this.room = roomData.data;
			this.setRoomFetching("success");
		},
		setRoomFetching(status: TRoomFetching) {
			this.roomFetching = status;
		},
	},
	getters: {
		getRoom(): IRoom | null {
			return this.room;
		},
		getRoomFetching(): TRoomFetching {
			return this.roomFetching;
		},
	},
});
