export function wsHandleGenerateError(response: IWsResponseError<IWsResponse<IAiGenerateMessageData>>): void {
	const {
		generateAgentMessageStore: { setAiGeneratesMessage, setAiGeneratedSuccess },
		modalErrorStore: { open: showError },
	} = useStores();

	setAiGeneratedSuccess(false);
	setAiGeneratesMessage(false);
	showError(response.error, true);
}
