import { defineStore } from "pinia";
import { $useFetch } from "~/composables/useFetch";
import { api } from "~/api/api";
import type { AsyncData } from "#app";
import type { ComputedRef } from "vue";

export const useAgentChatStore = defineStore("agent-chat", {
	state: (): {
		message: string;
	} => ({
		message: "",
	}),
	actions: {
		setMessage(message: string): void {
			this.message = message;
		},
		clearMessage(): void {
			this.message = "";
		},
		sendMessage(): AsyncData<IApiResponsePayload<IApiResponseStatus> | null, TFetchError> {
			const { activeEventStore, activeChatStore } = useStores();
			const { getActiveEvent } = storeToRefs(activeEventStore);
			const { getActiveChat } = storeToRefs(activeChatStore);

			const body: ComputedRef<ISendMessageBody> = computed(() => ({
				agent: getActiveChat.value?.agent ?? "",
				event: getActiveEvent.value?.id ?? "",
				message: this.message,
			}));

			return $useFetch<IApiResponsePayload<IApiResponseStatus>, TFetchError>(api.message, {
				method: "POST",
				body,
				immediate: false,
				watch: false,
			});
		},
	},
	getters: {
		getMessage(): string {
			return this.message;
		},
	},
});
