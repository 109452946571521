import { wsHandleGenerateMessage } from "~/utils/ws/ws-handle-response/ws-handle-generate-message";
import { wsHandleUpdateAll } from "~/utils/ws/ws-handle-response/ws-handle-update-all";
import { wsHandleGenerateError } from "~/utils/ws/ws-handle-response/ws-handle-generate-error";
import { wsHandleUpdateOrgChat } from "~/utils/ws/ws-handle-response/ws-handle-update-org-chat";

export const wsCommandHandlers: Record<TWsResponseCommands, (response: any) => Promise<void> | void> = {
	generate_message: (response: IWsResponse<IAiAgentGenerateMessage>) => wsHandleGenerateMessage(response),
	update_all: async (response: IWsResponseUpdateAll<TAiResponseUpdateAll>) => await wsHandleUpdateAll(response),
	generate_error: (response: IWsResponseError<IWsResponse<IAiGenerateMessageData>>) => wsHandleGenerateError(response),
	update_org_chat: (response: IWsResponseUpdateAll<IWsResponseUpdateOrg>) => wsHandleUpdateOrgChat(response),
};
