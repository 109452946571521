import { useActiveChatStore } from "~/store/active-chat/active-chat";
import { useActiveEventStore } from "~/store/active-event/active-event";
import { useChatListStore } from "~/store/chat-list/chat-list";
import { useEventsStore } from "~/store/events/events";
import { useLastMessageStore } from "~/store/last-message/last-message";
import { useAgentChatStore } from "~/store/agent-chat/agent-chat";
import { useMessagesStore } from "~/store/messages/messages";
import { useModalError } from "~/store/modal-error/modal-error";
import { useWsStore } from "~/store/ws/ws";
import { useRoomStore } from "~/store/room/room";
import { useOrgChatStore } from "~/store/org-chat/org-chat";
import { useGenerateAgentMessageStore } from "~/store/generate-agent-message/generate-agent-message";
import { useGenerateOrgMessageStore } from "~/store/generate-org-message/generate-org-message";

export const useStores = () => {
	const activeChatStore = useActiveChatStore();
	const activeEventStore = useActiveEventStore();
	const chatListStore = useChatListStore();
	const eventsStore = useEventsStore();
	const lastMessageStore = useLastMessageStore();
	const messageStore = useAgentChatStore();
	const messagesStore = useMessagesStore();
	const modalErrorStore = useModalError();
	const wsStore = useWsStore();
	const generateAgentMessageStore = useGenerateAgentMessageStore();
	const roomStore = useRoomStore();
	const orgChatStore = useOrgChatStore();
	const generateOrgMessageStore = useGenerateOrgMessageStore();

	return {
		activeChatStore,
		activeEventStore,
		chatListStore,
		eventsStore,
		lastMessageStore,
		messageStore,
		messagesStore,
		modalErrorStore,
		wsStore,
		generateAgentMessageStore,
		roomStore,
		orgChatStore,
		generateOrgMessageStore,
	};
};
