import { defineStore } from "pinia";

import { hasAnyEventAttribute } from "~/utils/has-any-event-attribute";

export const useActiveEventStore = defineStore("active-event", {
	state: (): {
		activeEvent: null | IEventAdvanced;
	} => ({
		activeEvent: null,
	}),
	actions: {
		async setActiveEvent(event: IEventAdvanced): Promise<void> {
			this.activeEvent = event;

			const {
				roomStore: { fetchEventRoom },
			} = useStores();
			await fetchEventRoom();
		},
		setActiveEventByLastOutMessage(): boolean {
			const {
				lastMessageStore,
				activeEventStore: { setActiveEvent },
				eventsStore,
			} = useStores();

			const { getLastOutMessage } = storeToRefs(lastMessageStore);

			const { getEventsId, getEventById, getEventIndex } = storeToRefs(eventsStore);
			const lastOutMessage = getLastOutMessage.value;

			if (!lastOutMessage || !lastOutMessage.event || !getEventsId.value.includes(lastOutMessage.event)) return false;

			const event = getEventById.value(lastOutMessage.event);
			const eventIndex = getEventIndex.value(lastOutMessage.event);

			setActiveEvent({
				...(event as IEvent),
				number: eventIndex + 1,
				hasAnyAttribute: hasAnyEventAttribute(event as IEvent),
			});

			return true;
		},
	},
	getters: {
		getActiveEvent(): IEventAdvanced | null {
			return this.activeEvent;
		},
		hasActiveEvent(): boolean {
			return !!this.activeEvent;
		},
		getActiveEventId(): string | undefined {
			return this.activeEvent?.id;
		},
		getEventTrigger(): boolean {
			return !!this.activeEvent?.is_org_trigger;
		},
	},
});
