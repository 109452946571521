import { useIsActiveAgent } from "~/composables/useIsActiveAgent";

export async function wsHandleUpdateAll(response: IWsResponseUpdateAll<TAiResponseUpdateAll>) {
	const {
		chatListStore: { fetchChats },
		lastMessageStore: { readLastMessage },
	} = useStores();

	const { activeChatStore } = useStores();
	const activeAgentId = activeChatStore.getActiveChat?.agent;

	const agentsToFetch = [...response.agents];
	if (response.agent) agentsToFetch.push(response.agent);

	await Promise.all(agentsToFetch.map((agent) => fetchMultiData(agent)));

	if (activeAgentId && (useIsActiveAgent(response.agent) || response.agents.includes(activeAgentId))) {
		await readLastMessage();
	} else {
		await fetchChats();
	}
}
