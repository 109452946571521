import { defineStore } from "pinia";
import { api } from "~/api/api";

export const useLastMessageStore = defineStore("last-message", {
	state: (): {
		lastMessage: IMessage | null;
		lastOutMessage: IMessage | null;
	} => ({
		lastMessage: null,
		lastOutMessage: null,
	}),
	actions: {
		setLastMessage(messages: IMessage[]) {
			this.lastMessage = messages.slice().reverse()[0] ?? null;
			this.lastOutMessage =
				messages
					.slice()
					.reverse()
					.find((message: IMessage) => message.type === "out") ?? null;
		},
		async readLastMessage() {
			if (!this.getLastMessage) return null;

			const {
				chatListStore: { fetchChats },
			} = useStores();

			const body: IMessageReadBody = {
				agent: this.getLastMessage.agent,
			};

			await useOFetch<IApiResponsePayload<IApiResponseMessage>>(api.messageRead(this.lastMessageId), {
				method: "POST",
				body,
			});

			fetchChats();
		},
	},
	getters: {
		getLastMessage(): IMessage | null {
			return this.lastMessage;
		},
		getLastOutMessage(): IMessage | null {
			return this.lastOutMessage;
		},
		lastMessageId(): string | null {
			return this.lastMessage?.id ?? null;
		},
		lastMessageIsReaded(): string | null {
			return this.lastMessage?.readed ?? null;
		},
	},
});
