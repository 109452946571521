import { defineStore } from "pinia";

export const useOrgChatStore = defineStore("org-chat", {
	state: (): {
		message: string;
	} => ({
		message: "",
	}),
	actions: {
		setMessage(message: string): void {
			this.message = message;
		},
		clearMessage(): void {
			this.message = "";
		},
		sendMessage() {},
	},
	getters: {
		getMessage(): string {
			return this.message;
		},
	},
});
