import { defineStore } from "pinia";
import { api } from "~/api/api";
import type { IFetchError } from "ofetch";
import { saveToCache } from "~/utils/save-to-cache";

export const useMessagesStore = defineStore("messages", {
	state: (): {
		messages: IMessage[];
	} => ({
		messages: [],
	}),
	actions: {
		async setMessages(messages: IMessage[]): Promise<void> {
			this.messages = messages;

			const {
				lastMessageStore: { setLastMessage, readLastMessage },
			} = useStores();

			setLastMessage(this.messages);
			await readLastMessage();
		},
		async fetchMessages() {
			const {
				activeChatStore,
				modalErrorStore: { open: showError },
				lastMessageStore: { readLastMessage },
			} = useStores();

			try {
				const messages = await useOFetch<IApiResponsePayload<IMessage[]>>(api.agentMessages(activeChatStore.getActiveChat?.agent), {
					method: "GET",
				});

				this.setMessages(messages.data ?? []);

				saveToCache({
					agentId: activeChatStore.getActiveChat?.agent ?? "",
					messages: messages.data,
				});

				await readLastMessage();
			} catch (error) {
				const fetchError = error as IFetchError;

				showError(fetchError.data?.message ?? fetchError.message ?? "Ошибка получения списка сообщений", true);
			}
		},
	},
});
