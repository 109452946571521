import { defineStore } from "pinia";
import { api } from "~/api/api";
import { type IFetchError } from "ofetch";
import { useOFetch } from "~/composables/useOFetch";

export const useChatListStore = defineStore("chat-list", {
	state: (): {
		chatList: IApiResponsePayload<IChatItem[]>;
		chatListError: string;
		search: string;
	} => ({
		chatList: {
			data: [],
			meta: [],
		},
		chatListError: "",
		search: "",
	}),
	actions: {
		async fetchChats(): Promise<void> {
			const setError = this.setChatListError;

			const data: IApiResponsePayload<IChatItem[]> | undefined | void = await useOFetch<IApiResponsePayload<IChatItem[]> | undefined>(api.chats, {
				method: "GET",
				params: {
					"filter[agent_name]": this.search,
				},
			}).catch((error: IFetchError) => {
				setError(error.data?.message ?? error.message ?? "Ошибка загрузки списка чатов");
			});

			if (data) this.chatList = data;
		},
		setChatListError(error: string): void {
			this.chatListError = error;
		},
		async setSearch(agentName: string): Promise<void> {
			this.search = agentName;

			await this.fetchChats();
		},
	},
	getters: {
		getChatList(): IApiResponsePayload<IChatItem[]> {
			return this.chatList;
		},
		getChatListError(): string {
			return this.chatListError;
		},
		getChatSearchValue(): string {
			return this.search;
		},
	},
});
