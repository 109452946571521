import { useChatCache } from "~/composables/useChatCache";

interface cacheData {
	agentId: string;
	messages: IMessage[];
	events?: IEvent[];
}

export function saveToCache(data: cacheData): void {
	const { agentId, events, messages } = data;
	const cache = useChatCache(agentId);

	if (!cache.value) {
		cache.value = { events: [], messages: [] };
	}

	cache.value.messages = messages;

	if (events) cache.value.events = events;
}
