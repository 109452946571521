export function wsHandleGenerateMessage(response: IWsResponse<IAiAgentGenerateMessage>) {
	const message: string = response.data.message;
	const {
		messageStore: { setMessage },
		generateAgentMessageStore: { setAiGeneratesMessage, setAiGeneratedSuccess },
		modalErrorStore: { open: showError },
	} = useStores();

	if (message) {
		if (useIsActiveAgent(response.agent)) {
			setMessage(message);
		}

		setAiGeneratedSuccess(true);
	} else {
		setAiGeneratedSuccess(false);
		showError(`${response.data}`, true);
	}

	setAiGeneratesMessage(false);
}
