import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAiAgentMessageToast, LazyAiOrgMessageToast, LazyChatManager, LazyChatMenu, LazyCheckmarksReading, LazyCollapseMenu, LazyDialogHistory, LazyDialogHistoryManagerDialogHistory, LazyGenerateAiAgentMessage, LazyGenerateAiOrgMessage, LazyLoader, LazyLogo, LazyModalContent, LazyModalError, LazyModal, LazyPageLoader, LazySearch, LazySendMessage, LazyStopEvent, LazyTextInfo, LazyUploadFile, LazyUploadFilesContent, LazyUploadFilesTeleportContent, LazyUserAvatar, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AiAgentMessageToast", LazyAiAgentMessageToast],
["AiOrgMessageToast", LazyAiOrgMessageToast],
["ChatManager", LazyChatManager],
["ChatMenu", LazyChatMenu],
["CheckmarksReading", LazyCheckmarksReading],
["CollapseMenu", LazyCollapseMenu],
["DialogHistory", LazyDialogHistory],
["DialogHistoryManagerDialogHistory", LazyDialogHistoryManagerDialogHistory],
["GenerateAiAgentMessage", LazyGenerateAiAgentMessage],
["GenerateAiOrgMessage", LazyGenerateAiOrgMessage],
["Loader", LazyLoader],
["Logo", LazyLogo],
["ModalContent", LazyModalContent],
["ModalError", LazyModalError],
["Modal", LazyModal],
["PageLoader", LazyPageLoader],
["Search", LazySearch],
["SendMessage", LazySendMessage],
["StopEvent", LazyStopEvent],
["TextInfo", LazyTextInfo],
["UploadFile", LazyUploadFile],
["UploadFilesContent", LazyUploadFilesContent],
["UploadFilesTeleportContent", LazyUploadFilesTeleportContent],
["UserAvatar", LazyUserAvatar],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
