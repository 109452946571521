import revive_payload_client_4sVQNw7RlN from "/opt/docker/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/docker/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/docker/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/docker/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/docker/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/docker/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/docker/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/docker/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/docker/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/docker/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/docker/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/opt/docker/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_AOuQ1DYzjk from "/opt/docker/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import dayjs_FoMn5Zigri from "/opt/docker/plugins/dayjs.js";
import debounce_4dZlUi0mTt from "/opt/docker/plugins/debounce.js";
import sentry_client_shVUlIjFLk from "/opt/docker/plugins/sentry.client.ts";
import user_roles_gJLc3b4AO1 from "/opt/docker/plugins/user-roles.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_AOuQ1DYzjk,
  dayjs_FoMn5Zigri,
  debounce_4dZlUi0mTt,
  sentry_client_shVUlIjFLk,
  user_roles_gJLc3b4AO1
]