import { defineStore } from "pinia";
import ReconnectingWebSocket from "reconnecting-websocket";
import { wsMessageHandle } from "~/utils/ws/ws-message-handle";

export const useWsStore = defineStore("ws", {
	state: (): {
		ws: ReconnectingWebSocket | null;
	} => ({
		ws: null,
	}),
	actions: {
		async wsInitial(): Promise<void> {
			const config = useRuntimeConfig();
			const {
				generateAgentMessageStore: { setAiGeneratesMessage, setAiGeneratedSuccess },
				generateAgentMessageStore,
			} = useStores();

			const { isAiGenerateMessage } = storeToRefs(generateAgentMessageStore);

			return new Promise<void>((resolve, reject): void => {
				if (this.ws) {
					resolve();
					return;
				}

				this.ws = new ReconnectingWebSocket(config.public.CHAT_WS_URL, [], {
					maxReconnectionDelay: 1500,
					minReconnectionDelay: 1000,
				});

				this.ws.addEventListener("open", (): void => {
					resolve();
				});

				this.ws.addEventListener("message", (event: MessageEvent): void => {
					wsMessageHandle(event);
				});

				this.ws.addEventListener("error", (): void => {
					console.error("[error]: Ошибка WS");
					if (isAiGenerateMessage.value) {
						setAiGeneratedSuccess(false);
						setAiGeneratesMessage(false);
					}

					reject();
				});

				this.ws.addEventListener("close", (): void => {});
			});
		},
		wsClose() {
			if (!this.ws) return;

			this.ws.close();
			this.ws = null;
		},
		wsSend<E extends TWsRequestCommands>(message: IWsMessageData<E>): void {
			if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
				console.error("Соединение не открыто");
				return;
			}
			const config = useRuntimeConfig();

			const payload = {
				api_key: config.public.CHAT_WS_API_KEY,
				...message,
			};

			this.ws.send(JSON.stringify(payload));
		},
	},
});
