const v1 = "/api";

export const api = {
	codeToTokens: `${v1}/dispatcher/user/oauth/token`,
	refreshTokens: `${v1}/dispatcher/user/oauth/refresh`,
	userSession: (session) => `${v1}/user/sessions/${session}`,
	userInfo: `${v1}/user/me`,
	chats: `${v1}/dispatcher/chats`,
	multiDetail: (id) => `${v1}/dispatcher/chats/agent/${id}`,
	aiMode: (uuid) => `${v1}/dispatcher/event/${uuid}`,
	message: `${v1}/dispatcher/message`,
	agentMessages: (id) => `${v1}/dispatcher/chat/${id}/messages`,
	eventCall: (id) => `${v1}/dispatcher/events/${id}/call`,
	createEvent: `${v1}/dispatcher/event`,
	startEvent: (id) => `${v1}/dispatcher/events/${id}/accept`,
	stopEvent: (id) => `${v1}/dispatcher/event/${id}/stop`,
	closeEvent: (id) => `${v1}/dispatcher/event/${id}/close`,
	agents: (id) => `${v1}/dispatcher/event/${id}/group-agents`,
	messageRead: (id) => `${v1}/dispatcher/messages/${id}/read`,
	fileUpload: (agentId) => `${v1}/dispatcher/${agentId}/file`,
	eventRoom: (id) => `${v1}/dispatcher/event/${id}/room`,
	rooms: (id) => `${v1}/dispatcher/rooms/${id}`,
	roomMessage: (id) => `${v1}/dispatcher/rooms/${id}/messages`,
	roomStage: (id) => `${v1}/dispatcher/rooms/${id}/stage`,
	roomAgents: (id) => `${v1}/dispatcher/rooms/${id}/agents`,
	roomMessageRead: (id) => `${v1}/dispatcher/rooms/messages/${id}/read`,
	farmerAgents: (farmerId) => `${v1}/farmers/${farmerId}/agents`,
	orgMessageAi: (groupId) => `${v1}/dispatcher/rooms/${groupId}/ai`,
};
