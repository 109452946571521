import { wsCommandHandlers } from "~/utils/ws/ws-command-handlers";

export async function wsMessageHandle(event: MessageEvent): Promise<void> {
	const { data } = event;
	if (typeof data !== "string") return;

	const {
		modalErrorStore: { open: showError },
	} = useStores();

	try {
		const wsResponse: IWsResponse<any> = JSON.parse(data);
		const wsCommand: TWsResponseCommands = wsResponse.command;

		const handler = wsCommandHandlers[wsCommand];

		if (handler) {
			handler(wsResponse);
		} else {
			console.error("Неизвестный тип websocket ответа");
		}
	} catch {
		showError(data, true);
	}
}
