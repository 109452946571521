import { defineStore } from "pinia";
import { hasAnyEventAttribute } from "~/utils/has-any-event-attribute";

export const useEventsStore = defineStore("events", {
	state: (): {
		events: IEvent[];
	} => ({
		events: [],
	}),
	actions: {
		setEvents(events: IEvent[]): void {
			if (!events.length) {
				const { roomStore } = useStores();
				roomStore.$reset();

				return;
			}

			this.events = events;

			const {
				activeEventStore: { setActiveEventByLastOutMessage },
			} = useStores();

			if (!setActiveEventByLastOutMessage()) {
				this.setActiveEvent();
			}
		},
		setActiveEvent() {
			if (!this.events.length) return;

			const {
				activeEventStore: { setActiveEvent },
			} = useStores();

			setActiveEvent({
				...this.events[0],
				number: 1,
				hasAnyAttribute: hasAnyEventAttribute(this.events[0]),
			});
		},
		updateEventRobot(activeEventId: string, robot: boolean) {
			this.events.find((event: IEvent) => {
				if (event.id === activeEventId) {
					event.robot = robot;
				} else {
					event.robot = false;
				}
			});
		},
	},
	getters: {
		getEvents(): IEvent[] {
			return this.events;
		},
		getNumberEvents(): number {
			return this.events.length;
		},
		getEventsId(): string[] {
			return this.events.map((event) => event.id);
		},
		getEventById:
			(state) =>
			(eventId: string): IEvent | undefined =>
				state.events.find((val) => val.id === eventId),
		getEventIndex:
			(state) =>
			(eventId: string): number =>
				state.events.map((event) => event.id).indexOf(eventId),
	},
});
